import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Navbar.css';
import { FaHome, FaTasks, FaCoins, FaUsers } from 'react-icons/fa';

function Navbar() {
    return (
        <nav className="navbar">
            <Link to="/" className="nav-item">
                <FaHome className="nav-icon" />
                <span>Home</span>
            </Link>
            <Link to="/earn" className="nav-item">
                <FaCoins className="nav-icon" />
                <span>Earn</span>
            </Link>
            <Link to="/tasks" className="nav-item">
                <FaTasks className="nav-icon" />
                <span>Tasks</span>
            </Link>
            <Link to="/friends" className="nav-item">
                <FaUsers className="nav-icon" />
                <span>Friends</span>
            </Link>
        </nav>
    );
}

export default Navbar;
