import React from 'react';
import '../styles/Friends.css';

function InviteFrens() {
    return (
        <div className="invite-page">
            {/* Верхняя часть с кнопкой "Connect Wallet" */}
            <button className="wallet-button-fixed">Connect Wallet</button>

            {/* Заголовок Invite Frens */}
            <div className="invite-header">
                <img src="/5.png" alt="family-icon" className="emoji-family-icon" />
                <h2>Invite Frens</h2>
            </div>

            {/* Секция с балансом */}
            <div className="balance-section">
                <div className="balance-box">
                    <div className="balance">₿0.000</div>
                    <button className="claim-button">Claim</button>
                </div>
                <p className="balance-info">
                    Score 10% from buddies + 2.5% from their referrals. Get a 🎟️ play pass for each fren.
                </p>
            </div>

            {/* Промо-блок для акции */}
            <div className="promo-section">
                <div className="promo-info">
                    <div className="promo-title">
                        <span role="img" aria-label="trophy">🏆</span> 700,000 USDT Giveaway
                    </div>
                    <div className="promo-date">6th August</div>
                </div>
             <a href='/'> <button className="view-button">View</button> </a>
            </div>

            {/* Счетчик друзей */}
            <div className="frens-counter">
                <p className="frens-text">0 frens</p>
            </div>

            {/* Кнопка для приглашения друзей */}
            <button className="invite-button-fixed">Invite a fren</button>
        </div>
    );
}

export default InviteFrens;
