import React from 'react';
import '../styles/Tasks.css';
import '../styles/App.css';

function Tasks() {
  const tasks = [
    //{ id: 1, title: "Subscribe to Blum Telegram", points: 1200, icon: "/3.png" }, 
    { id: 2, title: "Subscribe to Tappy Bird", points: 600, icon: "/1.png", url: "https://t.me/tappy_bird"},
    { id: 3, title: "Subscribe to Token Trends", points: 600, icon: "/1.png", url: "https://t.me/Trendsy_token"},
    { id: 4, title: "Subscribe to Satoshi's Secrets 🔑", points: 600, icon: "/1.png", url: "https://t.me/satoshis_secretss"},
    { id: 5, title: "Subscribe to CryptoQuest | enthusiast", points: 600, icon: "/1.png", url: "https://t.me/crypto_questt" },
    { id: 6, title: "Subscribe to Crypto Donut | News", points: 600, icon: "/1.png", url: "https://t.me/cryptodonut2" },
    { id: 7, title: "Subscribe to VAC.TRADE | TON | News", points: 600, icon: "/1.png", url: "https://t.me/vactrade" },
    { id: 8, title: "Subscribe to Осведомлённый | crypto enthusiast", points: 600, icon: "/1.png", url: "https://t.me/osvedcr"},
    { id: 9, title: "Subscribe to CryptoBoom 💥", points: 600, icon: "/1.png", url : "https://t.me/cryptobolic" },
    { id: 10, title: "Subscribe to Dogs Community", points: 600, icon: "/1.png", url: "https://t.me/dogs_community" },
  ];

  return (
    <div className="tasks-page">
      <h2>11 task available</h2>
      <p>We'll reward you immediately with points after each task completion.</p>

      <ul className="tasks-list">
        {tasks.map(task => (
          <li key={task.id} className="task-item">
            <div className="task-icon">
              <img src={task.icon} alt="Task icon" />
            </div>
            <div className="task-info">
              <span className="task-title">
                <a className="link" target="_blank" rel="noopener noreferrer" href={task.url}> {task.title}</a>
              </span>
              <span className="task-points">+ {task.points} BP</span>
            </div>
            <a className="link" target="_blank" rel="noopener noreferrer" href={task.url}> <button className="claim-button">Claim</button> </a>
          </li>
        ))}
      </ul>

      <div className="bottom-menu">
        <div className="menu-item">
          <img src="/home-icon.svg" alt="Home" />
          <span>Home</span>
        </div>
        <div className="menu-item">
          <img src="/earn-icon.svg" alt="Earn" />
          <span>Earn</span>
        </div>
        <div className="menu-item">
          <img src="/tasks-icon.svg" alt="Tasks" />
          <span>Tasks</span>
        </div>
        <div className="menu-item">
          <img src="/frens-icon.svg" alt="Frens" />
          <span>Frens</span>
        </div>
      </div>
    </div>
  );
}

export default Tasks;
