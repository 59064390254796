import React, { useState } from 'react';
import '../styles/Stake.css';
import '../styles/App.css';

function Stake() {
  const [stakeAmount, setStakeAmount] = useState(50);
  const [daylyEarn, updateDaylyEarn] = useState(18.8);
  const [yearEarn, updateYearsEarn] = useState(6860.90);


  const earnings = {
    daily: 18.75,
    weekly: 131.25,
    monthly: 562.50,
    quarterly: 1687.50,
  };

  const dailyEarns = {
    5: 1.88,
    10: 3.76,
    25 : 9.40,
    50 : 18.80,
    100 : 37.59,
  };


  const yearEarns = {
    5: 686.09,
    10: 1372.18,
    25 : 3430.45,
    50 : 6860.90,
    100 : 13721.80,
  };


  const handleStakeChange = (amount) => {
    setStakeAmount(amount);
    
    let dayly_amount = dailyEarns[amount]
    let year_amount = yearEarns[amount]

    updateDaylyEarn(dayly_amount);

    updateYearsEarn(year_amount);

  };


 



  return (
    <div className="stake-page">
      <button className="connectButton wallet-button">Connect Wallet</button>

      {/* Заголовок и информация о заработке */}
      <div className="earn-info">
        <h2 className="earn-title">You could earn</h2>
        <p className="earn-amount">
          <span className="earn-value">{daylyEarn} USD</span>
          <span className="daily-text">* daily</span>
        </p>
      </div>

      {/* Серая плашка для выбора стейка */}
      <div className="stake-calculator">
        <h3 className="stake-title">You stake</h3>
        <div className="stake-selected">{stakeAmount}</div>
        <div className="stake-options">
          {[5, 10, 25, 50, 100].map((amount) => (
            <button
              key={amount}
              className={stakeAmount === amount ? "selected" : ""}
              onClick={() => handleStakeChange(amount)}
            >
              {amount}
            </button>
          ))}
        </div>
        <img src="/10.png" alt="TON logo" className="ton-logo" /> {/* Логотип TON */}
      </div>

      {/* Информация о заработке */}
      <div className="earnings-info">
        <div className="earnings-row">
          <p>Earn per day</p>
          <p>≈{daylyEarn} USD</p>
        </div>
 
        <div className="earnings-row">
          <p>Earn per 12 months</p>
          <p>≈{yearEarn} USD</p>
        </div>
      </div>

      {/* Overview Section */}
      <div className="overview">
        <h3>Overview</h3>
        <div className="overview-items">
          <div className="overview-item">
            <img src="/7.png" alt="Withdraw" />
            <p>Withdraw at any time</p>
          </div>
          <div className="overview-item">
            <img src="/8.png" alt="Rewards" />
            <p>Rewards every ...</p>
          </div>
          <div className="overview-item">
            <img src="/9.png" alt="Min stake" />
            <p>Min stake of 0.5 TON</p>
          </div>
        </div>
      </div>

      {/* Protocol Details */}
      <div className="protocol-details">
        <h3>Protocol details</h3>
        <div className="protocol-info">
          <p>#1 Staking on TON</p>
          <p>34.5M TON TVL</p>
          <p>70245 Stakers now</p>
        </div>
      </div>

      {/* Security Rating */}
      <div className="security-rating">
        <h3>Security rating</h3>
        <div className="rating-score">
          <img src="/11.png" alt="Shield" />
          <span>94.17 / 100</span>
          <span className="high-score">High score</span>
        </div>
        <p className="highlights">7 highlights & 0 alerts</p>
        <p className="security-info">Security info provided by <a className ="link" href="https://skynet.certik.com/projects/tonstakers" target="_blank" rel="noopener noreferrer"> Certik </a></p>
      </div>

      {/* Серая плашка с дополнительной информацией */}
      <div className="extra-info-card">
        <div className="extra-info-row">
          <p>Total staked</p>
          <p>34,645,108 TON</p>
        </div>
        <div className="extra-info-row">
          <p>Stakers</p>
          <p>70245</p>
        </div>
        <div className="extra-info-row">
          <p>Instantly available liquidity</p>
          <p>49,182 TON</p>
        </div>
        <div className="extra-info-row">
          <p>Pool address</p>
          <p><a className="link light" href = "https://tonviewer.com/EQCkWxfyhAkim3g2DjKQQg8T5P4g-Q1-K_jErGcDJZ4i-vqR" target="_blank" rel="noopener noreferrer"> EQCkWx...vqR </a> </p>
        </div>
        <div className="extra-info-row">
          <p>Reward token address</p>
          <p><a className="link light" href = "https://tonviewer.com/EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs" target="_blank" rel="noopener noreferrer"> EQCB2e...0vy </a> </p>

        </div>
      </div>

      {/* Текст над кнопкой */}
      <p className="stake-info-text">By using app you agree to our terms of service.</p>
      {/* Текст внизу страницы */}
      <p className="stake-bottom-text">* Staking rewards are sent in BUSD in fixed exchange rate of 1 USD = 1 BUSD</p>

      {/* Кнопка "Stake TON" */}
      <button className="stake-button">Stake TON</button>

    </div>
  );
}

export default Stake;
